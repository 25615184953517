import React, { useContext, useState } from 'react';
import { includes, map } from 'lodash';
import { Container, Draggable } from 'react-smooth-dnd';
import { getComponentsForCategory, getHumanText, getPreviewImage } from '../../../utils';
import { getCustomBlockContent } from './BlockComponentCustom';
import { Link } from 'react-router-dom';
import { routes } from '../../data/constants';
import { customBlockSelectHandler, modalOpenHandler } from '../../data/actions';
import { MODAL_TYPES } from '../../data/reducers';
import { DispatchContext, StateContext } from '../../../App';
import { DefaultPageTypeNames } from '../../../constants';

const onlyAdmin = ['account'];

const ComponentCategories = [
  { id: 'general', description: 'General' },
  { id: 'content', description: 'Content' },
  { id: 'vehicle', description: 'Vehicle' },
  { id: 'features', description: 'Features' },
  { id: 'contact', description: 'Contact' },
  { id: 'seo', description: 'SEO' },
  // {id: 'blog', description: 'Blog'},
  { id: 'account', description: 'Account' },
  { id: 'purchase', description: 'Purchase' },
  { id: 'custom', description: 'Custom' }
];

export const Menu = ({ context, customBlocks }) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [category, setCategory] = useState(undefined);
  const [dragStarted, setDragStarted] = useState(false);
  const isSuperUser = state.session.user.isSuperUser;

  const categoryComponents = getComponentsForCategory(category);

  const componentsWithPreview = map(categoryComponents, (comp) => ({
    ...comp,
    preview: getPreviewImage(comp.name, context)
  }));

  const onCreatePage = (pageType) => {
    modalOpenHandler(dispatch, MODAL_TYPES.PAGE_CREATE, { type: pageType });
  };

  const onCreateCustomBlock = () => {
    modalOpenHandler(dispatch, MODAL_TYPES.BLOCK_CREATE);
  };

  const onEditCustom = (customBlock) => {
    customBlockSelectHandler(dispatch, customBlock.name);
    modalOpenHandler(dispatch, MODAL_TYPES.BLOCK_CREATE);
  };

  return (
    <div className='builder-h-full builder-bg-secondary-darker builder-text-white builder-overflow-y-scroll' style={{ width: 160 }}>
      {/*BLOCKS*/}
      {isSuperUser && (
        <Link to={routes.BLOCKS} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fa fa-cube builder-mr-2 builder-text-gray-400' />
          Blocks
        </Link>
      )}
      {/*PAGES*/}
      <div className='builder-flex builder-flex-col'>
        <div className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fas fa-copy builder-mr-2 builder-text-gray-400' />
          Pages
        </div>
        <div className='builder-flex builder-flex-col builder-bg-secondary-dark builder-text-sm'>
          <Link to={routes.PAGES} className='builder-px-3.5 builder-my-3.5 builder-cursor-pointer'>
            All pages
          </Link>
          <div className='builder-px-3.5 builder-mb-3.5 builder-cursor-pointer' onClick={() => onCreatePage()}>
            Add new
          </div>
        </div>
      </div>

      {/*COMPONENTS*/}
      <div
        className='builder-flex builder-flex-col'
        onMouseLeave={() => {
          if (!dragStarted) {
            setCategory(undefined);
          }
        }}
      >
        <div className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fas fa-cubes builder-mr-2 builder-text-gray-400' />
          Components
        </div>
        <div className='builder-flex builder-flex-col'>
          <div className='builder-cursor-pointer builder-text-white builder-bg-secondary-dark'>
            {map(ComponentCategories, (cat, index) => {
              if (includes(onlyAdmin, cat.id) && !isSuperUser) {
                return null;
              }

              return (
                <MenuItem
                  id={cat.id}
                  key={`menu${cat.id}`}
                  isLast={index === ComponentCategories.length - 1}
                  onCreateCustomBlock={onCreateCustomBlock}
                  text={cat.description}
                  onMouseEnter={() => setCategory(cat.id)}
                  onClick={() => setCategory(cat.id)}
                />
              );
            })}
          </div>

          {((category && category !== 'custom') || dragStarted) && (
            <div
              className={`builder-absolute builder-border builder-border-gray-300 builder-shadow builder-bg-white builder-rounded-md builder-f-full builder-max-h-full builder-overflow-scroll  ${
                !dragStarted ? 'builder-p-2' : 'builder-overflow-hidden'
              }`}
              style={{
                top: 8,
                bottom: 8,
                left: 130,
                width: !dragStarted ? 800 : 0,
                zIndex: 999
              }}
            >
              <div className='builder-mb-4 builder-p-2 builder-text-black builder-text-md'>
                <span>Select component and drag it to the canvas</span>
              </div>

              <div className='builder-p-2 builder-text-black'>
                <Container
                  groupName='1'
                  behaviour='copy'
                  style={{ flex: 1, width: '100%' }}
                  onDragStart={() => setDragStarted(true)}
                  onDragEnd={() => {
                    setCategory(undefined);
                    setDragStarted(false);
                  }}
                  getChildPayload={(i) => componentsWithPreview[i]}
                >
                  {componentsWithPreview.map((comp, i) => {
                    return (
                      <Draggable key={`${comp.name}_${i}`} className='mt-2 builder-text-black'>
                        <div className='builder-flex builder-flex-1 builder-w-full builder-border-2 builder-border-white hover:builder-border-blue-600' style={{ zIndex: 999 }}>
                          <div className='builder-w-full builder-flex builder-flex-1 builder-cursor-pointer builder-border builder-border-gray-300  builder-flex-col'>
                            <div className='builder-text-center builder-p-2'>{getHumanText(comp.name, 'en')}</div>

                            <div className='relative builder-text-black'>
                              <div
                                className='absolute'
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  zIndex: 900
                                }}
                              ></div>
                              {comp.preview}
                            </div>
                          </div>
                        </div>
                      </Draggable>
                    );
                  })}
                </Container>
              </div>
            </div>
          )}

          {((category && category === 'custom') || dragStarted) && (
            <div
              className={`builder-absolute builder-border builder-border-gray-300 builder-shadow builder-bg-white builder-rounded-md builder-f-full builder-max-h-full builder-overflow-scroll  ${
                !dragStarted ? 'builder-p-2' : 'builder-overflow-hidden'
              }`}
              style={{
                top: 8,
                bottom: 8,
                left: 130,
                width: !dragStarted ? 800 : 0,
                zIndex: 999
              }}
            >
              <div className='builder-p-2 builder-text-black'>
                <Container
                  groupName='1'
                  behaviour='copy'
                  style={{ flex: 1, width: '100%' }}
                  onDragStart={() => setDragStarted(true)}
                  onDragEnd={() => {
                    setCategory(undefined);
                    setDragStarted(false);
                  }}
                  getChildPayload={(i) => customBlocks[i]}
                >
                  {map(customBlocks, (customBlock) => {
                    return (
                      <Draggable key={customBlock.uuid} className='mt-2 builder-text-black'>
                        <div className='builder-flex builder-flex-1 builder-w-full builder-border-2 builder-border-white hover:builder-border-blue-600' style={{ zIndex: 999 }}>
                          <div className='builder-w-full builder-flex builder-flex-1 builder-cursor-pointer builder-border builder-border-gray-300  builder-flex-col builder-p-2'>
                            <div className='builder-text-center builder-p-2'>{customBlock.description}</div>

                            <div className='relative builder-text-black'>
                              <div
                                className='absolute'
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  zIndex: 900
                                }}
                              />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: getCustomBlockContent(customBlock.data, 'nl')
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='builder-flex builder-justify-end builder-w-full'>
                          <div className='builder-bg-primary builder-text-white builder-border builder-border-bg-blue-500 builder-px-4 builder-py-1 builder-cursor-pointer' onClick={() => onEditCustom(customBlock)}>
                            EDIT
                          </div>
                        </div>
                      </Draggable>
                    );
                  })}
                </Container>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='builder-flex builder-flex-col'>
        <div className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fal fa-thumbtack builder-mr-2 builder-text-gray-400' />
          Blog
        </div>
        <div className='builder-flex builder-flex-col builder-bg-secondary-dark builder-text-sm'>
          <Link to={routes.BLOG} className='builder-px-3.5 builder-my-3.5 builder-cursor-pointer'>
            All blog posts
          </Link>
          <div className='builder-px-3.5 builder-mb-3.5 builder-cursor-pointer' onClick={() => onCreatePage(DefaultPageTypeNames.blogPost)}>
            Add new
          </div>
        </div>
      </div>

      <Link to={routes.CAMPAIGNS} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
        <i className='fab fa-adversal builder-mr-2 builder-text-gray-400' />
        Campaigns
      </Link>

      <Link to={routes.ADVERTISEMENTS} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
        <i className='fab fa-adversal builder-mr-2 builder-text-gray-400' />
        Advertisements
      </Link>

      <div className='builder-flex builder-flex-col'>
        <div className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fal fa-question-square builder-mr-2 builder-text-gray-400' />
          FAQ
        </div>
        <div className='builder-flex builder-flex-col builder-bg-secondary-dark builder-text-sm'>
          <Link to={routes.FAQ_OVERVIEW} className='builder-px-3.5 builder-my-3.5 builder-cursor-pointer'>
            Overview
          </Link>
          <Link to={routes.FAQ_TOPICS} className='builder-px-3.5 builder-my-3.5 builder-cursor-pointer'>
            Topics
          </Link>
        </div>
      </div>

      {isSuperUser && (
        <Link to={routes.VEHICLE_FILTERS} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fas fa-search builder-mr-2 builder-text-gray-400' />
          Filters
        </Link>
      )}

      {isSuperUser && (
        <Link to={routes.SETTINGS_DEALERSHIPS} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
          <i className='fas fa-home builder-mr-2 builder-text-gray-400' />
          Dealerships
        </Link>
      )}

      <Link to={routes.SETTINGS_GENERAL} className='builder-flex builder-items-center builder-px-3.5 builder-py-3.5 builder-text-md builder-font-bold'>
        <i className='fas fa-cog builder-mr-2 builder-text-gray-400' />
        Settings
      </Link>
    </div>
  );
};

const MenuItem = ({ id, text, onMouseEnter, onClick, isLast, onCreateCustomBlock }) => {
  const isCustom = id === 'custom';
  return (
    <div className={`builder-flex builder-flex-col builder-justify-center builder-px-3.5 builder-mt-3.5 ${isLast && 'builder-mb-3.5'}`}>
      <div className={`builder-flex builder-items-center`} key={`menu_item_${id}`} onMouseEnter={onMouseEnter} onClick={onClick}>
        <span>{text}</span>
      </div>
      {isCustom && (
        <div className='builder-flex builder-items-center builder-mt-2 builder-cursor-pointer' onClick={() => onCreateCustomBlock()}>
          <i className='far fa-level-up-alt builder-ml-1 builder-mr-2 builder-text-gray-400' style={{ transform: 'rotate(90deg)' }} /> Create custom
        </div>
      )}
    </div>
  );
};
