import { ACTIONS } from './reducers';
import { v4 } from 'uuid';
import { map, includes } from 'lodash';

export const pageCreationHandler = (dispatch, id, type, path, copyId) => {
  dispatch({
    type: ACTIONS.PAGE_ADD,
    payload: {
      id: id,
      type,
      path,
      copyId
    }
  });
};

export const pageDeleteHandler = (dispatch, id) => {
  dispatch({
    type: ACTIONS.PAGE_DELETE,
    payload: {
      id
    }
  });
};

export const pageMetaChange = (dispatch, pageId, culture, meta) => {
  dispatch({
    type: ACTIONS.PAGE_META_CHANGE,
    payload: {
      pageId,
      culture,
      meta
    }
  });
};

export const pageChange = (dispatch, page) => {
  dispatch({
    type: ACTIONS.PAGE_CHANGE,
    payload: {
      page
    }
  });
};

const mapLanguagesByConfiguration = (configuration) => {
  const config = JSON.parse(configuration);
  const allLanguages = ['nl', 'fr', 'en'];
  const availableLanguages = config.website.languages;
  return map(allLanguages, (l) => ({
    code: l,
    available: includes(availableLanguages, l)
  }));
};

export const dataSetHandler = (dispatch, data) => {
  dispatch({
    type: ACTIONS.DATA_SET,
    payload: {
      currentWebsiteId: data.website.id,
      pages: data.website.pages,
      pageTags: data.tags,
      pageContent: data.pageContent,
      campaigns: data.campaigns,
      advertisementGroups: data.advertisementGroups,
      advertisements: data.advertisements,
      vehicleSearchFilters: data.vehicleSearchFiltersData,

      customBlocks: data.website.customBlocks,
      configuration: JSON.parse(data.configuration),
      languages: mapLanguagesByConfiguration(data.configuration),
      contentTypeDefinitions: data.contentTypeDefinitions,
      dealerships: data.dealerships,
      dealershipLocationTypes: data.dealershipLocationTypes,

      websites: data.websites,
      dealerMakes: data.dealerMakes,
      bodyTypes: data.bodyTypes,
      fuels: data.fuels,
      gearboxes: data.gearboxes,
      categories: data.categories,
      labels: data.labels,
      equipments: data.features,
      exteriorColors: data.exteriorColors,
      interiorColors: data.interiorColors,
      interiorUpholsteries: data.interiorUpholsteries,
      emissionNorms: data.euroNorms
    }
  });
};

export const pageBlockCreationHandler = (dispatch, pageId, index, component) => {
  dispatch({
    type: ACTIONS.PAGE_BLOCK_ADD,
    payload: {
      pageId,
      blockId: v4(),
      component,
      index
    }
  });
};

export const pageBlockOrderChangedHandler = (dispatch, pageId, removedIndex, addedIndex, block) => {
  dispatch({
    type: ACTIONS.PAGE_BLOCK_ORDER,
    payload: {
      pageId,
      removedIndex,
      addedIndex,
      block
    }
  });
};

export const pageBlockComponentChangedHandler = (dispatch, pageId, blockId, componentName) => {
  dispatch({
    type: ACTIONS.PAGE_BLOCK_COMPONENT_CHANGE,
    payload: {
      pageId,
      blockId,
      componentName
    }
  });
};

export const pageBlockContentChange = (dispatch, pageId, blockId, field, value, culture) => {
  dispatch({
    type: ACTIONS.PAGE_BLOCK_CONTENT_CHANGE,
    payload: {
      pageId,
      blockId,
      field,
      value,
      culture
    }
  });
};

export const pageBlockFieldChange = (dispatch, pageId, blockId, field, value) => {
  dispatch({
    type: ACTIONS.PAGE_BLOCK_FIELD_CHANGE,
    payload: {
      pageId,
      blockId,
      field,
      value
    }
  });
};

export const pageBlockContentCopy = (dispatch, pageId, blockId, copyBlock, culture) => {
  dispatch({
    type: ACTIONS.PAGE_BLOCK_CONTENT_COPY,
    payload: {
      pageId,
      blockId,
      copyBlock,
      culture
    }
  });
};

export const pageBlockContentCopyToAll = (dispatch, block, lmaPage = false) => {
  dispatch({
    type: ACTIONS.PAGE_BLOCK_CONTENT_COPY_TO_ALL,
    payload: {
      block,
      lmaPage
    }
  });
};

export const pageBlockDeleteHandler = (dispatch, pageId, blockId) => {
  dispatch({
    type: ACTIONS.PAGE_BLOCK_DELETE,
    payload: {
      pageId,
      blockId
    }
  });
};

export const undoHandler = (dispatch) => {
  dispatch({
    type: ACTIONS.UNDO,
    payload: {}
  });
};

export const environmentChange = (dispatch, websiteId) => {
  dispatch({
    type: ACTIONS.WEBSITE_SELECT,
    payload: {
      websiteId
    }
  });
};

export const pageSelectHandler = (dispatch, id) => {
  dispatch({
    type: ACTIONS.PAGE_SELECT,
    payload: {
      id
    }
  });
};

export const blockSelectHandler = (dispatch, id) => {
  dispatch({
    type: ACTIONS.BLOCK_SELECT,
    payload: {
      id
    }
  });
};

export const languageActiveChangeHandler = (dispatch, languageCode, available) => {
  dispatch({
    type: ACTIONS.LANGUAGES_CHANGE,
    payload: {
      languageCode,
      available
    }
  });
};

export const copyContentHandler = (dispatch, from, to) => {
  dispatch({
    type: ACTIONS.CONTENT_COPY,
    payload: {
      from,
      to
    }
  });
};

export const setSession = (dispatch, session) => {
  dispatch({
    type: ACTIONS.SESSION_SET,
    payload: {
      session
    }
  });
};

export const setLastAction = (dispatch) => {
  dispatch({
    type: ACTIONS.LAST_ACTION_SET,
    payload: {}
  });
};

export const seoPageContentCreateHandler = (dispatch, key, language, type, filters, text) => {
  dispatch({
    type: ACTIONS.SEO_PAGE_CONTENT_ADD,
    payload: {
      key,
      language,
      type,
      filters,
      text
    }
  });
};

export const seoPageContentUpdateHandler = (dispatch, key, language, text) => {
  dispatch({
    type: ACTIONS.SEO_PAGE_CONTENT_UPDATE,
    payload: {
      key,
      language,
      text
    }
  });
};

export const customBlockCreationHandler = (dispatch, description, culture, data) => {
  dispatch({
    type: ACTIONS.CUSTOM_BLOCK_ADD,
    payload: {
      name: v4(),
      contentType: 'CustomGeneratedBlockInBuilder',
      culture: culture,
      description: description,
      data: data
    }
  });
};

export const customBlockEditHandler = (dispatch, name, description, culture, data) => {
  dispatch({
    type: ACTIONS.CUSTOM_BLOCK_EDIT,
    payload: {
      name: name,
      culture: culture,
      description: description,
      data: data
    }
  });
};

export const pageBlockCampaignChangedHandler = (dispatch, pageId, blockId, campaignId) => {
  dispatch({
    type: ACTIONS.PAGE_BLOCK_CAMPAIGN_CHANGE,
    payload: {
      pageId,
      blockId,
      campaignId
    }
  });
};

export const pageBlockHideForCampaignHandler = (dispatch, pageId, blockId, hide) => {
  dispatch({
    type: ACTIONS.PAGE_BLOCK_HIDE_FOR_CAMPAIGN_CHANGE,
    payload: {
      pageId,
      blockId,
      hide
    }
  });
};

export const modalOpenHandler = (dispatch, type, payload = {}) => {
  dispatch({
    type: ACTIONS.MODAL_OPEN,
    payload: {
      type,
      payload: payload
    }
  });
};

export const modalCloseHandler = (dispatch, type) => {
  dispatch({
    type: ACTIONS.MODAL_CLOSE,
    payload: {
      type
    }
  });
};

export const campaignAddHandler = (dispatch, name, start, stop, isDraft) => {
  dispatch({
    type: ACTIONS.CAMPAIGN_ADD,
    payload: {
      name,
      start,
      stop,
      isDraft
    }
  });
};

export const websiteCreationHandler = (dispatch, name, startFrom, campaignCleanUp = true) => {
  dispatch({
    type: ACTIONS.WEBSITE_ADD,
    payload: {
      name: name,
      startFrom: startFrom,
      campaignCleanUp: campaignCleanUp
    }
  });
};

export const campaignFilterChangeHandler = (dispatch, filter) => {
  dispatch({
    type: ACTIONS.CAMPAIGN_FILTER_CHANGE,
    payload: {
      filter: filter
    }
  });
};

export const campaignDeleteHandler = (dispatch, id) => {
  dispatch({
    type: ACTIONS.CAMPAIGN_DELETE,
    payload: {
      id
    }
  });
};

export const campaignSelectHandler = (dispatch, id) => {
  dispatch({
    type: ACTIONS.CAMPAIGN_SELECT,
    payload: {
      id
    }
  });
};

export const campaignEditHandler = (dispatch, id, name, start, stop) => {
  dispatch({
    type: ACTIONS.CAMPAIGN_EDIT,
    payload: {
      id,
      name,
      start,
      stop
    }
  });
};

export const customBlockSelectHandler = (dispatch, id) => {
  dispatch({
    type: ACTIONS.CUSTOM_BLOCK_SELECT,
    payload: {
      id
    }
  });
};

export const publishStartHandler = (dispatch, filter) => {
  dispatch({
    type: ACTIONS.PUBLISH_START,
    payload: {}
  });
};

export const publishLogAddHandler = (dispatch, data) => {
  dispatch({
    type: ACTIONS.PUBLISH_LOG_ADD,
    payload: { data }
  });
};

export const publishLogClearHandler = (dispatch) => {
  dispatch({
    type: ACTIONS.PUBLISH_LOG_CLEAR,
    payload: {}
  });
};

export const sessionRestrictionModalViewSetHandler = (dispatch, view) => {
  dispatch({
    type: ACTIONS.RESTRICTION_MODEL_VIEW_SET,
    payload: {
      view
    }
  });
};

export const sessionTakeOverMessageSetHandler = (dispatch, message, view) => {
  dispatch({
    type: ACTIONS.RESTRICTION_TAKE_OVER_MESSAGE_SET,
    payload: {
      message: message,
      view: view
    }
  });
};

export const advertisementInitHandler = (dispatch, { id, description, content, isDraft, filters }) => {
  dispatch({
    type: ACTIONS.ADVERTISEMENT_INIT,
    payload: {
      id,
      description,
      content,
      isDraft,
      filters
    }
  });
};

export const advertisementEditHandler = (dispatch, { id, description, content, groups, filters }) => {
  dispatch({
    type: ACTIONS.ADVERTISEMENT_EDIT,
    payload: {
      id,
      description,
      content,
      groups,
      filters
    }
  });
};

export const advertisementDeleteHandler = (dispatch, id) => {
  dispatch({
    type: ACTIONS.ADVERTISEMENT_DELETE,
    payload: {
      id
    }
  });
};

export const advertisementGroupInitHandler = (dispatch, id, name, isDraft) => {
  dispatch({
    type: ACTIONS.ADVERTISEMENT_GROUP_INIT,
    payload: {
      id,
      name,
      isDraft
    }
  });
};

export const advertisementGroupEditHandler = (dispatch, id, name) => {
  dispatch({
    type: ACTIONS.ADVERTISEMENT_EDIT,
    payload: {
      id,
      name
    }
  });
};

export const advertisementGroupDeleteHandler = (dispatch, id) => {
  dispatch({
    type: ACTIONS.ADVERTISEMENT_DELETE,
    payload: {
      id
    }
  });
};

export const websiteDisable = (dispatch, id) => {
  dispatch({
    type: ACTIONS.WEBSITE_DISABLE,
    payload: {
      id
    }
  });
};

export const vehicleSearchFilterInitHandler = (dispatch, { id, name, type, filters, isDraft }) => {
  dispatch({
    type: ACTIONS.VEHICLE_FILTER_INIT,
    payload: {
      id,
      name,
      type,
      filters,
      isDraft
    }
  });
};

export const vehicleSearchFilterEditHandler = (dispatch, { id, name, type, filters, isDraft }) => {
  dispatch({
    type: ACTIONS.VEHICLE_FILTER_EDIT,
    payload: {
      id,
      name,
      type,
      filters,
      isDraft
    }
  });
};

export const vehicleSearchFilterDeleteHandler = (dispatch, id) => {
  dispatch({
    type: ACTIONS.VEHICLE_FILTER_DELETE,
    payload: {
      id
    }
  });
};

export const stateReset = (dispatch) => {
  dispatch({
    type: ACTIONS.RESET_STATE,
    payload: {}
  });
};

export const pagePropertiesChange = (dispatch, pageId, type, culture, data) => {
  dispatch({
    type: ACTIONS.PAGE_PROPERTIES_CHANGE,
    payload: {
      pageId,
      type,
      culture,
      data
    }
  });
};

export const pageTagsChange = (dispatch, pageId, tags) => {
  dispatch({
    type: ACTIONS.PAGE_TAGS_CHANGE,
    payload: {
      pageId,
      tags
    }
  });
};

export const pageTagAdd = (dispatch, tag) => {
  dispatch({
    type: ACTIONS.PAGE_TAG_ADD,
    payload: {
      tag
    }
  });
};
