import React, { useEffect, useState } from 'react';
import Header from './Header';
import { routes } from '../../../data/constants';
import cogoToast from 'cogo-toast';
import { useMutation, useQuery } from 'graphql-hooks';
import { SettingsQuery, SettingsUpdateMutation } from '../../../data/graphql';
import { reduce } from 'lodash';
import ConfigurationEditor from './ConfigurationEditor';
import { useHistory } from 'react-router-dom';
import { ErrorIndicator, LoadingIndicator } from '../../../../components/common';
import ConfirmBox from '../../../../components/common/ConfirmBox';

const mapSettings = (settings) => {
  return reduce(
    settings,
    (result, value, key) => {
      let newValue = value;
      if (value === 'True' || value === 'true') newValue = true;
      if (value === 'False' || value === 'false') newValue = false;
      return { ...result, [key]: newValue };
    },
    {}
  );
};

const unmapSettings = (settings) => {
  return reduce(
    settings,
    (result, value, key) => {
      let newValue = value;
      if (value === true) newValue = 'true';
      if (value === false) newValue = 'false';
      return { ...result, [key]: newValue };
    },
    {}
  );
};

const Configuration = ({ isSuperUser, currentState, context }) => {
  const [settings, setSettings] = useState({});
  const [initSettings, setInitSettings] = useState({});
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const [saveMutation, { loading: saving }] = useMutation(SettingsUpdateMutation);
  const { loading, error, data } = useQuery(SettingsQuery);
  const history = useHistory();

  useEffect(() => {
    if (!loading) {
      setSettings(mapSettings(data.settings));
      setInitSettings({
        settings: mapSettings(data.settings)
      });
      setNeedsConfirmation(false);
    }
  }, [loading, data]);

  if (loading && !error) {
    return <LoadingIndicator />;
  }

  if (!loading && error) {
    return <ErrorIndicator error='Settings could not be fetched.' />;
  }

  const hasChanges = JSON.stringify(settings?.configuration) !== JSON.stringify(initSettings?.settings?.configuration);

  const handleCancel = () => {
    if (hasChanges) {
      setNeedsConfirmation(true);
    } else {
      history.push(routes.HOME);
    }
  };

  const handleGeneralSettingsSave = () => {
    saveMutation({
      variables: {
        data: {
          settings: unmapSettings(settings)
        }
      }
    })
      .then((res) => {
        const { ok } = res.data.settingsUpdate;
        if (ok) {
          cogoToast.success('Settings saved!');

          setInitSettings({
            settings: settings
          });

          setNeedsConfirmation(false);
        }
      })
      .catch((error) => {
        cogoToast.error('Error saving settings!');
      });
  };

  return (
    <React.Fragment>
      {needsConfirmation && (
        <ConfirmBox
          title='Are you sure?'
          text='You have unsaved changes. If you procceed they will be lost.'
          onCancel={() => setNeedsConfirmation(false)}
          onConfirm={() => {
            history.push(routes.HOME);
          }}
        />
      )}
      <Header onCancel={handleCancel} onSave={handleGeneralSettingsSave} loading={loading} saveDisabled={!hasChanges || saving} />
      <ConfigurationEditor settings={settings} isSuperUser={isSuperUser} onChange={(updatedSettings) => setSettings(updatedSettings)} data={data} />
    </React.Fragment>
  );
};

export default Configuration;
