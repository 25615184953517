import { find, get, has, isString, map, orderBy, trim } from 'lodash';
import { tryParse } from '../../utils';

export const updatePriorities = (data, draggedItem, targetItem) => {
  const orderedData = map(orderBy(data, 'priority'), (item, index) => ({ ...item, priority: index }));
  const draggedItemPriority = find(orderedData, (item) => item.id === draggedItem.id)?.priority;
  const targetItemPriority = find(orderedData, (item) => item.id === targetItem.id)?.priority;

  if (draggedItemPriority === undefined || targetItemPriority === undefined || draggedItemPriority === null || targetItemPriority === null) {
    return orderedData;
  }

  const minPriority = Math.min(draggedItemPriority, targetItemPriority);
  const maxPriority = Math.max(draggedItemPriority, targetItemPriority);

  const newData = map(orderedData, (item) => {
    if (item.priority === draggedItemPriority) {
      return { ...item, priority: targetItemPriority - 0.5 };
    } else if (item.priority >= minPriority && item.priority <= maxPriority) {
      const priorityOffset = targetItemPriority < draggedItemPriority ? 0.5 : -0.5;
      return { ...item, priority: item.priority + priorityOffset };
    }
    return item;
  });

  // return orderBy(newData, 'priority');
  return map(orderByPriority(newData), (item, index) => ({
    ...item,
    priority: index
  }));
  // const draggedItemPriority = draggedItem?.priority
  // const targetItemPriority = targetItem?.priority
  //
  // if (draggedItemPriority === undefined || targetItemPriority === undefined || draggedItemPriority === null || targetItemPriority === null) {
  //   return data
  // }
  //
  //
  // // Get index of draggedItem and targetItem (to can check when have same priorities)
  // const draggedItemIndex = data.findIndex((item) => item.id === draggedItem.id)
  // const targetItemIndex = data.findIndex((item) => item.id === targetItem.id)
  //
  // // Set new priority for draggedItem
  // // - if draggedItem move to forward, then new priority greater than targetPriority
  // //   - OR have same priorities and targetItemIndex greater than draggedItemIndex
  // // - else draggedItem move to backward, so new prioirty smaller than targetPriority
  // // data[draggedItemIndex].priority = targetItemPriority > draggedItemPriority || (targetItemPriority === draggedItemPriority && targetItemIndex > draggedItemIndex)
  // //   ? targetItemPriority + 0.5
  // //   : targetItemPriority - 0.5
  //
  // // Add dragged item always before target item
  // data[draggedItemIndex].priority = targetItemPriority - 0.5
  //
  // // Order By Priority, and set new priority values by index
  // return map(orderBy(data, 'priority'), (item, index) => {
  //   item.priority = index
  //   return item
  // })
};

export const containsOnlyLowercaseAndHyphens = (text) => {
  return isString(text) && /^[a-z-]+$/.test(text);
};

export const convertToSlug = (text) => {
  if (text === undefined || text === null || text === '') return '';
  if (!isString(text)) return text;
  return trim(text.replaceAll(' ', '-').toLowerCase(), '_-.,');
};

export const resolveTranslationFromJson = (jsonData, key) => {
  const parsedData = tryParse(jsonData);
  if (has(parsedData, key)) {
    return get(parsedData, key);
  }

  return jsonData;
};

export const orderByPriority = (data, orderType = 'asc') => {
  return orderBy(data, 'priority', orderType);
};

export const isPriorityChanged = (initialState, newState) => {
  if (!initialState || !newState) return false;

  let priorityChanged = false;

  for (const category of newState.categories) {
    const categoryInInitialState = find(initialState.categories, (initialCategory) => initialCategory.id === category.id);
    if (!categoryInInitialState || category.priority !== categoryInInitialState.priority) {
      priorityChanged = true;
      break;
    }
  }

  if (!priorityChanged) {
    for (const questionGroup of newState.questionGroups) {
      const questionGroupInInitialState = find(initialState.questionGroups, (initialQuestionGroup) => initialQuestionGroup.id === questionGroup.id);
      if (!questionGroupInInitialState || questionGroup.priority !== questionGroupInInitialState.priority) {
        priorityChanged = true;
        break;
      }
    }
  }

  if (!priorityChanged) {
    for (const question of newState.questions) {
      const questionInInitialState = find(initialState.questions, (initialQuestion) => initialQuestion.id === question.id);
      if (!questionInInitialState || question.priority !== questionInInitialState.priority) {
        priorityChanged = true;
        break;
      }
    }
  }

  return priorityChanged;
};
