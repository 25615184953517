import { keys, find, mapValues, keyBy, filter, map, capitalize, includes, values, pickBy, forEach, omit, orderBy } from 'lodash';
import * as components from 'dealer-website-components';
import { componentDetails } from 'dealer-website-components/dist/builder-components-info';
import previews from 'dealer-website-components/dist/preview';
import React from 'react';
import ImagePreview from './features/builder/components/ImagePreview';

const imagesExtensions = ['jpeg', 'jpg', 'png', 'svg', 'webp', 'ico'];
const videoExtensions = ['mov', 'mp4', 'm4v'];

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const objectNotEmpty = (object) => {
  return keys(object).length > 0;
};

export const definitionsForContentType = (definitions, contentType) => {
  const definition = find(definitions, (def) => def.name === contentType);

  if (definition) {
    return definition.fields;
  } else return [];
};

export const getFieldsForContent = (block, culture) => {
  const filtered = filter(block.content, (content) => content.culture === culture);
  if (filtered.length >= 1) {
    return filtered[0].fields;
  }
  return null;
};

export const getKeyValueContentFieldsForBlock = (block, culture) => {
  const content = getFieldsForContent(block, culture);
  return mapValues(keyBy(content, 'name'), 'value');
};

export const getFieldByName = (fields, name) => {
  const filtered = filter(fields, (field) => field.name === name);
  if (filtered.length >= 1) {
    return filtered[0];
  }
  return null;
};

export const getContentForField = (block, fieldName, culture) => {
  const content = getKeyValueContentFieldsForBlock(block, culture);
  return content[fieldName] ? content[fieldName] : undefined;
};

export const parseFields = (fields) => {
  const parsed = map(fields, (field) => ({
    ...field,
    value: parseField(field)
  }));
  return parsed;
};

export const saveField = (content) => {
  if (content.type && content.type.startsWith('Json')) {
    return JSON.stringify(content.value);
  } else if (content.type && content.type === 'BooleanField') {
    return capitalize(content.value);
  } else {
    return content.value;
  }
};

export const parseField = (field) => {
  if (field.type && field.type.startsWith('Json')) {
    let value = '';
    if (field.value !== '') {
      try {
        value = JSON.parse(field.value);
      } catch (ex) {
        value = field.value;
      }
    }
    return value;
  } else {
    return field.value;
  }
};

export const copyBlocks = (blocksToCopy) => {
  return;
};

export const getExtension = (url) => {
  if (url && url !== '' && url !== null && includes(url, '.')) {
    return url.split('.').pop();
  }
  return '';
};

export const getFileName = (url) => {
  if (url && url !== '' && url !== null && includes(url, '.')) {
    return url.substring(url.lastIndexOf('/') + 1);
  }
  return '';
};

export const fileUsed = (url, pagesJson) => {
  return includes(pagesJson, url);
};

export const isExtensionAllowed = (ext) => {
  if (includes(imagesExtensions, ext) || includes(videoExtensions, ext)) {
    return true;
  }
  return false;
};

export const isImage = (url) => {
  const ext = getExtension(url);
  return includes(imagesExtensions, ext);
};

export const isVideo = (url) => {
  const ext = getExtension(url);
  return includes(videoExtensions, ext);
};

export const getComponentDetails = (components) => {
  if (componentDetails) {
    return componentDetails;
  } else {
    return {};
  }
};

export const getComponentsForCategory = (category) => {
  return values(pickBy(getComponentDetails(components), (value, key) => value.category === category));
};

export const getComponentsForContentType = (contentType) => {
  return values(pickBy(getComponentDetails(components), (value, key) => value.contentType === contentType));
};

export const getPreviewComponent = (name, context) => {
  // Try to resolve preview component
  try {
    const preview_name = `${name}`;
    const comp = components[preview_name];

    if (comp && comp.preview) {
      return <comp.preview context={context} />;
    } else {
      return <div>No component preview implementation</div>;
    }
    //return (comp && comp.preview) ? comp.preview : undefined;
  } catch (ex) {
    return <div>No component preview implementation</div>;
  }
};

export const getPreviewImage = (name, context) => {
  // Try to resolve preview component
  try {
    const preview_name = `${name}`;
    const image = previews[preview_name];
    // return (image) ? image : undefined;
    if (image) {
      return <ImagePreview url={image} context={context} />;
    } else {
      return <div>No component preview implementation</div>;
      // bw compatible old method
      //return getPreviewComponent(name, context)
    }
  } catch (ex) {
    return <div>No component preview implementation</div>;
  }
};

export const getHumanText = (name, language) => {
  // Try to resolve human name
  try {
    const preview_name = `${name}`;
    const comp = components[preview_name];

    const human = comp && comp.default?.human ? comp.default.human : comp && comp.human ? comp.human : null;
    return human && human[language] ? human[language] : name;
  } catch (ex) {
    return name;
  }
};

export const getDayTranslation = (day) => {
  switch (day) {
    case Days.mon:
      return 'Maandag';
    case Days.tue:
      return 'Dinsdag';
    case Days.wed:
      return 'Woensdag';
    case Days.thu:
      return 'Donderdag';
    case Days.fri:
      return 'Vrijdag';
    case Days.sat:
      return 'Zaterdag';
    case Days.sun:
      return 'Zondag';
    default:
      return '';
  }
};

export const mapArrayToString = (arr, separator = ',') => {
  let result = '';
  forEach(arr, (item) => {
    if (result === '') {
      result = item;
    } else {
      result = `${result}${separator}${item}`;
    }
  });
  return result;
};

export const tryParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (ex) {
    return value;
  }
};

export const jsonIsValid = (value) => {
  try {
    JSON.parse(value);
    return true;
  } catch (ex) {
    return false;
  }
};

export const getTranslationForPage = (page, lng) => {
  if (page.url === '/index/') return '';

  let result = page.url;

  if (page.slug[lng] && page.slug[lng] !== '') {
    result = page.slug[lng];
  }

  if (page.matchPath) {
    result = `${result}${page.matchPath}`;
  }

  return result;
};

export const hasChanges = (lastSavedAsJson, current) => {
  let last = {};
  if (lastSavedAsJson) {
    last = JSON.parse(lastSavedAsJson);
  }
  const var1 = omit(last, ['currentBlockId', 'currentPageId']);
  const var2 = omit(current, ['currentBlockId', 'currentPageId']);

  return JSON.stringify(var1) !== JSON.stringify(var2);
};

export const mapPagesToState = (pages) => {
  return map(pages, (page) => {
    const blocks_ordered = orderBy(page.blocks, ['order'], ['asc']);
    return {
      id: page.id || page.uuid,
      url: page.url,
      type: page.type,
      slug: page.slug && page.slug.length > 0 ? JSON.parse(page.slug) : undefined,
      migration: page.migration,
      creationDate: page.creationDate,
      tags: page.tags,
      indexable: page.indexable,
      matchPath: page.matchPath && page.matchPath !== '' ? page.matchPath : undefined,
      analyticsName: page.analyticsName,
      analyticsType: page.analyticsType,
      meta: map(page.meta, (meta) => {
        return {
          id: meta.id || meta.uuid,
          title: meta.title,
          description: meta.description,
          language: meta.language.toLowerCase()
        };
      }),
      properties: map(page.properties, (prop) => {
        return {
          id: prop.id,
          type: prop.type,
          data: tryParse(prop.data),
          language: prop.language
        };
      }),
      blocks: map(blocks_ordered, (block, index) => {
        return {
          id: block.id || block.uuid,
          contentType: block.contentType,
          componentName: block.variant,
          content: map(block.content, (content) => {
            return {
              id: content.id || content.uuid,
              culture: content.culture,
              fields: parseFields(content.fields)
            };
          }),
          campaignId: block.campaignId,
          hideForCampaign: block.hideForCampaign,
          analyticsName: block.analyticsName
        };
      })
    };
  });
};

export const Days = {
  mon: 'mon',
  tue: 'tue',
  wed: 'wed',
  thu: 'thu',
  fri: 'fri',
  sat: 'sat',
  sun: 'sun'
};

export const validateStringWithSlashes = (inputString) => {
  // Regular expression to match a string with front and trailing slashes
  const regex = /^\/.*\/$/;

  return regex.test(inputString);
};

export const formatNumber = (number, minDigits = 0, maxDigits = 2) => {
  let nr = parseFloat(number);
  return nr.toLocaleString('nl-NL', { minimumFractionDigits: minDigits, maximumFractionDigits: maxDigits });
};
