import React, { isValidElement, useContext, useState } from 'react';
import { omit, orderBy } from 'lodash';
import { StateContext } from '../../../App';
import Page404 from '../../../components/Page404';
import FileUploader from './file-uploader/FileUploader';
import Release from './release/Release';
import BackupAndRestore from './backup-restore/BackupAndRestore';
import Configuration from './configuration/Configuration';
import Header from '../../../components/Header';
import { default as PageTypesOverview } from './page-types/overview/components/Overview';
import { default as RedirectsOverview } from './redirects/overview/components/Overview';

const DynamicComponent = (props) => {
  if (isValidElement(props.component)) {
    return React.cloneElement(props.component, omit(props, 'component'));
  }

  return <div>The component has not been created yet.</div>;
};

const generalSettings = [
  {
    title: 'Configuration JSON',
    mainComponent: <Configuration />,
    default: true,
    reference: 'configuration',
    icon: 'far fa-cog',
    position: 1
  },
  {
    title: 'File uploader',
    mainComponent: <FileUploader />,
    default: true,
    reference: 'file-uploader',
    icon: 'fas fa-images',
    position: 2
  },
  {
    title: 'Release',
    mainComponent: <Release />,
    default: true,
    reference: 'release',
    icon: 'far fa-upload',
    position: 3
  },
  {
    title: 'Backup and Restore',
    mainComponent: <BackupAndRestore />,
    default: true,
    reference: 'backup_and_restore',
    icon: 'far fa-database',
    position: 4
  },
  {
    title: 'Page types',
    mainComponent: <PageTypesOverview />,
    default: true,
    reference: 'page_types',
    icon: 'fad fa-file',
    position: 5
  },
  {
    title: 'Redirects',
    mainComponent: <RedirectsOverview />,
    default: true,
    reference: 'redirects',
    icon: 'fal fa-directions',
    position: 6
  }
];

const General = ({ context }) => {
  const [activeComponent, setActiveComponent] = useState(generalSettings.find((setting) => setting.default).reference);
  const state = useContext(StateContext);
  const isSuperUser = state.session.user.isSuperUser;
  const currentState = state.history[state.history.length - 1];

  const setting = generalSettings.find((setting) => setting.reference === activeComponent);

  if (!setting) {
    return <Page404 />;
  }

  const { mainComponent } = setting;
  return (
    <div className='builder-flex builder-flex-1 builder-flex-col builder-h-screen builder-font-body builder-text-sm'>
      <Header />

      <div className='builder-flex builder-bg-gray-50 builder-p-4 builder-items-center builder-sticky builder-top-0 builder-z-50 builder-border-b builder-border-slate-100 builder-mb-6' style={{ height: 60, minHeight: 60 }}>
        <div className='builder-font-bold builder-text-2xl builder-text-slate-800' style={{ maxWidth: 1600 }}>
          General settings
        </div>
      </div>

      <div className='builder-flex builder-items-center builder-justify-center builder-bg-white builder-h-screen'>
        <div className='builder-flex builder-w-full builder-h-full builder-px-4 builder-pb-6'>
          <div className='builder-border-r builder-border-gray-200' style={{ maxWidth: 220, width: 220, minWidth: 220 }}>
            {orderBy(generalSettings, ['position'], ['asc']).map((item, i) => {
              return (
                <div key={`${item.reference}-${i}`} className={`builder-p-3 builder-cursor-pointer hover:bg-gray-200 ${activeComponent === item.reference ? 'builder-bg-gray-100' : ''}`} onClick={() => setActiveComponent(item.reference)}>
                  <i className={`mr-2 ${item.icon}`} /> {item.title}
                </div>
              );
            })}
          </div>
          <div className='builder-w-full builder-ml-4 builder-overflow-scroll' style={{ maxHeight: 'calc(100vh - 146px - 1.5rem)' }}>
            <DynamicComponent component={mainComponent} isSuperUser={isSuperUser} currentState={currentState} context={context} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
