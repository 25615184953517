import { filter, find, map } from 'lodash';
import { formatMatchPath } from '../utils';

export const actionTypes = {
  DATA_SET_LOADING: 'DATA_SET_LOADING',
  DATA_RESET_LOADING: 'DATA_RESET_LOADING',
  DATA_SET: 'DATA_SET',
  PAGE_TYPE_DELETED: 'PAGE_TYPE_DELETED',
  PAGE_TYPE_ADD: 'PAGE_TYPE_ADD'
};

export const initialState = {
  loading: false,
  pageTypes: []
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.DATA_SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DATA_RESET_LOADING:
      return {
        ...state,
        loading: false
      };
    case actionTypes.DATA_SET:
      return {
        ...state,
        loading: false,
        pageTypes: action.payload.pageTypes
      };

    case actionTypes.PAGE_TYPE_DELETED:
      return {
        ...state,
        pageTypes: filter(state.pageTypes, (pageType) => pageType.id !== action.payload.pageTypeId)
      };

    case actionTypes.PAGE_TYPE_ADD:
      const pageTypeId = action.payload.pageType.id;
      const isExistingPageType = find(state.pageTypes, (pt) => pt.id === pageTypeId);
      let newPageTypes = [];

      if (isExistingPageType) {
        newPageTypes = map(state.pageTypes, (pt) => {
          if (pt.id === pageTypeId) {
            return {
              id: action.payload.pageType.id,
              isDraft: action.payload.pageType.isDraft,
              disabled: action.payload.pageType.disabled,
              immutable: action.payload.pageType.immutable,
              isTemplate: action.payload.pageType.isTemplate,
              matchPath: formatMatchPath(action.payload.pageType.matchPath),
              name: action.payload.pageType.name,
              path: action.payload.pageType.path,
              singleUse: action.payload.pageType.singleUse
            };
          }

          return pt;
        });
      } else {
        newPageTypes = [{ ...action.payload.pageType, matchPath: formatMatchPath(action.payload.pageType.matchPath) }, ...state.pageTypes];
      }
      return {
        ...state,
        pageTypes: newPageTypes
      };

    default:
      return state;
  }
};
