import React from 'react';

const Switch = ({ isChecked, onChange, label, size, direction, context }) => {
  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    onChange(e.target.checked);
  };

  if (size === 'small') {
    return (
      <label className='builder-relative builder-inline-flex builder-items-center builder-cursor-pointer'>
        <input type='checkbox' className='builder-sr-only builder-peer' checked={isChecked} onChange={handleCheckboxChange} />
        <div className="builder-w-9 builder-h-5 builder-bg-gray-200 builder-rounded-full builder-peer peer-checked:after:builder-translate-x-full peer-checked:after:builder-border-white after:builder-content-[''] after:builder-absolute after:builder-top-[2.5px] after:builder-left-[2px] after:builder-bg-white after:builder-border-gray-300 after:builder-border after:builder-rounded-full after:builder-h-4 after:builder-w-4 after:builder-transition-all peer-checked:builder-bg-blue-600"></div>
        {label && <span className='builder-ml-3 builder-text-sm builder-font-medium builder-text-gray-900'>{label}</span>}
      </label>
    );
  }

  if (size === 'large') {
    return (
      <label className='builder-relative builder-inline-flex builder-items-center builder-cursor-pointer'>
        <input type='checkbox' className='builder-sr-only builder-peer' checked={isChecked} onChange={handleCheckboxChange} />
        <div className="w-14 h-7 builder-bg-gray-200 peer-focus:builder-outline-none builder-rounded-full builder-peer peer-checked:after:builder-translate-x-full peer-checked:after:builder-border-white after:builder-content-[''] after:builder-absolute after:builder-top-0.5 after:builder-left-[4px] after:builder-bg-white after:builder-border-gray-300 after:builder-border after:builder-rounded-full after:builder-h-6 after:builder-w-6 after:builder-transition-all peer-checked:builder-bg-blue-600"></div>
        {label && <span className='builder-ml-3 builder-text-sm builder-font-medium builder-text-gray-900'>{label}</span>}
      </label>
    );
  }

  return (
    <label className='builder-relative builder-inline-flex builder-items-center builder-cursor-pointer'>
      <input type='checkbox' className='builder-sr-only builder-peer' checked={isChecked} onChange={handleCheckboxChange} />
      <div className="w-11 h-6 builder-bg-gray-200 builder-rounded-full builder-peer peer-checked:after:builder-translate-x-full after:builder-content-[''] after:builder-absolute after:builder-top-[2px] after:builder-left-[2px] after:builder-bg-white after:builder-border-gray-300 after:builder-border after:builder-rounded-full after:builder-h-5 after:builder-w-5 after:builder-transition-all peer-checked:builder-bg-blue-600"></div>
      {label && <span className='builder-ml-3 builder-text-sm builder-font-medium builder-text-gray-900'>{label}</span>}
    </label>
  );
};

Switch.defaultProps = {
  size: 'default',
  direction: 'row'
};

export default Switch;
