import React, { useRef } from 'react';
import { v4 } from 'uuid';

const TextInput = ({ value, placeHolder, onChanged, className, type, disabled, dataTestId, errorText, label }) => {
  const inputRef = useRef(null);
  const inputId = v4();
  // const [text, setText] = useState(value);
  // const [isFocused, setIsFocused] = useState(false);

  // useEffect(() => {
  //   setText(value);
  // }, [value, isFocused]);

  let inputClassName = 'builder-appearance-none builder-border builder-w-full builder-py-2 builder-px-3 builder-text-gray-700 builder-leading-tight focus:builder-outline-none builder-min-h-[38px]';
  inputClassName = errorText ? `${inputClassName} builder-border-red-500 builder-bg-red-50` : `${inputClassName} builder-border-gray-300`;

  return (
    <div className='builder-flex builder-flex-col'>
      {label && (
        <label htmlFor={inputId} className={`builder-text-md builder-font-bold builder-mb-1 ${errorText ? 'builder-text-red-400' : 'builder-text-black'}`}>
          {label}
        </label>
      )}
      <input
        id={inputId}
        className={`${inputClassName} ${className}`}
        type={type}
        data-testid={dataTestId}
        ref={inputRef}
        placeholder={placeHolder && placeHolder !== '' ? placeHolder : 'Add your text here'}
        value={value}
        //onChange={(e) => setText(e.target.value)}
        onChange={(e) => onChanged(e.target.value)}
        // onBlur={() => {
        //     onChanged(text)
        // }}
        onFocus={() => {
          inputRef.current.focus();
          inputRef.current.select();
        }}
        disabled={disabled}
      />
      {errorText && <div className='builder-mt-1 builder-text-sm builder-text-red-500 builder-whitespace-normal'>{errorText}</div>}
    </div>
  );
};

TextInput.defaultProps = {
  type: 'text',
  disabled: false
};

export default TextInput;
