import React from 'react';
import MonacoEditor from 'react-monaco-editor';

const ConfigurationEditor = ({ settings, isSuperUser, onChange, data }) => {
  if (!isSuperUser) {
    return <div> not allowed </div>;
  }

  const handleChange = (newvalue) => {
    onChange({ ...settings, configuration: newvalue });
  };

  const options = {
    selectOnLineNumbers: true,
    colorDecorators: true
  };

  return (
    <div className='builder-flex builder-flex-1 builder-h-full builder-mb-6'>
      <MonacoEditor language='json' theme='vs-dark' onChange={handleChange} options={options} defaultValue={JSON.stringify(JSON.parse(data.settings.configuration), null, 2)} />
    </div>
  );
};

export default ConfigurationEditor;
