import { endsWith, startsWith } from 'lodash';

export const isFromPathValid = (fromPath) => {
  // URL empty
  if (!fromPath) return false;

  // URL doesn't start with '/'
  if (!startsWith(fromPath, '/')) return false;

  return true;
};

export const isToPathValid = (toPath) => {
  // URL empty
  if (!toPath) return false;

  // URL doesn't start with '/'
  if (!startsWith(toPath, '/')) return false;

  const indexOfQuestionMark = toPath.indexOf('?');
  if (indexOfQuestionMark === -1) {
    // URL doesn't contain '?', so it needs to end with '/'
    if (!endsWith(toPath, '/')) return false;

    // URL doesn't contain '?', and ends with '/'
    return true;
  }

  const indexOfEqualMark = toPath.indexOf('=');
  if (indexOfEqualMark !== -1) {
    // URL does contain '=', so it needs to also have '?'
    if (indexOfQuestionMark === -1) {
      return false;
    }
  }

  // URL has '/' before '?', so it's valid
  if (indexOfQuestionMark > 0 && toPath[indexOfQuestionMark - 1] === '/') {
    return true;
  }

  return false; // URL has '?' but not '/' before it
};
