import { omit, cloneDeep, find, orderBy, map, keyBy, filter, forEach, values, includes, concat } from 'lodash';
import { v4 } from 'uuid';
import { mapPagesToState, tryParse } from '../../utils';
import { mapPages } from '../builder/useBuilderActions';
import { SessionRestrictionModalViews } from '../session/SessionRestrictionModal';

export const MODAL_TYPES = {
  DRAFT_CREATE: 'DRAFT_CREATE',
  PAGE_CREATE: 'CREATE_CREATE',
  CAMPAIGN_CREATE: 'CAMPAIGN-CREATE',
  BLOCK_CREATE: 'CUSTOM_BLOCK_CREATE',
  PUBLISH_WEBSITE: 'PUBLISH_WEBSITE',
  PUBLISH_DRAFT_TO_PRODUCTION: 'PUBLISH_DRAFT_TO_PRODUCTION',
  SESSION_TAKE_OVER_REQUEST: 'SESSION_TAKE_OVER_REQUEST',
  ADVERTISEMENT_GROUP_CREATE: 'ADVERTISEMENT_GROUP_CREATE'
};

export const ACTIONS = {
  WEBSITE_ADD: 'WEBSITE_ADD',
  WEBSITE_SELECT: 'WEBSITE_SELECT',
  WEBSITE_DISABLE: 'WEBSITE_DISABLE',

  PAGE_ADD: 'PAGE_ADD',
  PAGE_DELETE: 'PAGE_DELETE',
  PAGE_META_CHANGE: 'PAGE_META_CHANGE',
  PAGE_PROPERTIES_CHANGE: 'PAGE_PROPERTIES_CHANGE',
  PAGE_TAGS_CHANGE: 'PAGE_TAGS_CHANGE',
  PAGE_CHANGE: 'PAGE_CHANGE',

  PAGE_BLOCK_ADD: 'PAGE_BLOCK_ADD',
  PAGE_BLOCK_ORDER: 'PAGE_BLOCK_ORDER',
  PAGE_BLOCK_DELETE: 'PAGE_BLOCK_DELETE',
  PAGE_BLOCK_CONTENT_CHANGE: 'PAGE_BLOCK_CONTENT_CHANGE',
  PAGE_BLOCK_FIELD_CHANGE: 'PAGE_BLOCK_FIELD_CHANGE',
  PAGE_BLOCK_COMPONENT_CHANGE: 'PAGE_BLOCK_COMPONENT_CHANGE',
  PAGE_BLOCK_CONTENT_COPY: 'PAGE_BLOCK_CONTENT_COPY',
  PAGE_BLOCK_CONTENT_COPY_TO_ALL: 'PAGE_BLOCK_CONTENT_COPY_TO_ALL',
  PAGE_BLOCK_CAMPAIGN_CHANGE: 'PAGE_BLOCK_CAMPAIGN_CHANGE',
  PAGE_BLOCK_HIDE_FOR_CAMPAIGN_CHANGE: 'PAGE_BLOCK_HIDE_FOR_CAMPAIGN_CHANGE',

  PAGE_TAG_ADD: 'PAGE_TAG_ADD',

  SEO_PAGE_CONTENT_ADD: 'SEO_PAGE_CONTENT_ADD',
  SEO_PAGE_CONTENT_UPDATE: 'SEO_PAGE_CONTENT_UPDATE',
  SEO_PAGE_CONTENT_DELETE: 'SEO_PAGE_CONTENT_DELETE',

  CUSTOM_BLOCK_ADD: 'CUSTOM_BLOCK_ADD',
  CUSTOM_BLOCK_DELETE: 'CUSTOM_BLOCK_DELETE',

  CAMPAIGN_ADD: 'CAMPAIGN_ADD',
  CAMPAIGN_EDIT: 'CAMPAIGN_EDIT',
  CAMPAIGN_SELECT: 'CAMPAIGN_SELECT',
  CAMPAIGN_DELETE: 'CAMPAIGN_DELETE',
  CAMPAIGN_FILTER_CHANGE: 'CAMPAIGN_FILTER_CHANGE',

  PAGE_SELECT: 'PAGE_SELECT',
  BLOCK_SELECT: 'BLOCK_SELECT',
  CUSTOM_BLOCK_SELECT: 'CUSTOM_BLOCK_SELECT',
  CUSTOM_BLOCK_EDIT: 'CUSTOM_BLOCK_EDIT',
  UNDO: 'UNDO',
  DATA_SET: 'DATA_SET',
  LANGUAGES_CHANGE: 'LANGUAGES_CHANGE',
  CONTENT_COPY: 'CONTENT_COPY',

  SESSION_SET: 'SESSION_SET',
  LAST_ACTION_SET: 'LAST_ACTION_SET',

  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',

  PUBLISH_START: 'PUBLISH_START',
  PUBLISH_LOG_ADD: 'PUBLISH_LOG_ADD',
  PUBLISH_LOG_CLEAR: 'PUBLISH_LOG_CLEAR',

  RESTRICTION_MODEL_VIEW_SET: 'RESTRICTION_MODEL_VIEW_SET',
  RESTRICTION_TAKE_OVER_MESSAGE_SET: 'RESTRICTION_TAKE_OVER_MESSAGE_SET',

  ADVERTISEMENT_GROUP_INIT: 'ADVERTISEMENT_GROUP_INIT',
  ADVERTISEMENT_GROUP_EDIT: ' ADVERTISEMENT_GROUP_EDIT',
  ADVERTISEMENT_GROUP_DELETE: 'ADVERTISEMENT_GROUP_DELETE',

  ADVERTISEMENT_INIT: 'ADVERTISEMENT_INIT',
  ADVERTISEMENT_EDIT: ' ADVERTISEMENT_EDIT',
  ADVERTISEMENT_DELETE: 'ADVERTISEMENT_DELETE',

  VEHICLE_FILTER_INIT: 'VEHICLE_FILTER_INIT',
  VEHICLE_FILTER_EDIT: 'VEHICLE_FILTER_EDIT',
  VEHICLE_FILTER_DELETE: 'VEHICLE_FILTER_DELETE',

  RESET_STATE: 'RESET_STATE'
};

const initialPage = {
  id: v4(),
  url: 'index',
  type: 'home',
  slug: { nl: '', fr: '', de: '' },
  migration: false,
  tags: '',
  blocks: [],
  indexable: true,
  matchPath: undefined
};

const initialHistory = {
  pages: keyBy([{ ...initialPage }], 'id'),
  languages: [],
  pageContentSEO: [],
  customBlocks: [],
  advertisementGroups: [],
  advertisements: [],
  vehicleSearchFilters: [],
  currentPageId: undefined,
  currentBlockId: undefined
};

export const initialState = {
  currentWebsite: undefined,
  history: [{ ...initialHistory }],
  websites: [],
  loading: true,
  contentTypeDefinitions: undefined,
  session: undefined,
  lastAction: undefined,
  modal: undefined,
  campaignFilter: undefined,
  campaignSelected: undefined,
  customBlockSelected: undefined,
  publish: {
    output: ''
  },
  sessionRestrictionModalView: undefined,
  sessionTakeOverMessage: undefined
};

export const rootReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.PAGE_ADD:
    case ACTIONS.PAGE_DELETE:
    case ACTIONS.PAGE_META_CHANGE:
    case ACTIONS.PAGE_PROPERTIES_CHANGE:
    case ACTIONS.PAGE_TAGS_CHANGE:
    case ACTIONS.PAGE_CHANGE:
    case ACTIONS.PAGE_BLOCK_ADD:
    case ACTIONS.PAGE_BLOCK_DELETE:
    case ACTIONS.PAGE_BLOCK_ORDER:
    case ACTIONS.PAGE_BLOCK_CONTENT_CHANGE:
    case ACTIONS.PAGE_BLOCK_FIELD_CHANGE:
    case ACTIONS.PAGE_BLOCK_COMPONENT_CHANGE:
    case ACTIONS.PAGE_BLOCK_CONTENT_COPY:
    case ACTIONS.PAGE_BLOCK_CONTENT_COPY_TO_ALL:
    case ACTIONS.PAGE_BLOCK_CAMPAIGN_CHANGE:
    case ACTIONS.PAGE_BLOCK_HIDE_FOR_CAMPAIGN_CHANGE:
    case ACTIONS.CUSTOM_BLOCK_ADD:
    case ACTIONS.CUSTOM_BLOCK_DELETE:
    case ACTIONS.PAGE_SELECT:
    case ACTIONS.BLOCK_SELECT:
    case ACTIONS.LANGUAGES_CHANGE:
    case ACTIONS.CONTENT_COPY:
    case ACTIONS.UNDO:
    case ACTIONS.SEO_PAGE_CONTENT_ADD:
    case ACTIONS.SEO_PAGE_CONTENT_UPDATE:
    case ACTIONS.SEO_PAGE_CONTENT_DELETE:
    case ACTIONS.CAMPAIGN_ADD:
    case ACTIONS.CAMPAIGN_DELETE:
    case ACTIONS.CAMPAIGN_EDIT:
    case ACTIONS.CAMPAIGN_SELECT:
    case ACTIONS.CUSTOM_BLOCK_SELECT:
    case ACTIONS.CUSTOM_BLOCK_EDIT:
    case ACTIONS.ADVERTISEMENT_INIT:
    case ACTIONS.ADVERTISEMENT_EDIT:
    case ACTIONS.ADVERTISEMENT_DELETE:
    case ACTIONS.ADVERTISEMENT_GROUP_INIT:
    case ACTIONS.ADVERTISEMENT_GROUP_EDIT:
    case ACTIONS.ADVERTISEMENT_GROUP_DELETE:
    case ACTIONS.VEHICLE_FILTER_INIT:
    case ACTIONS.VEHICLE_FILTER_EDIT:
    case ACTIONS.VEHICLE_FILTER_DELETE:
      return {
        ...state,
        history: historyReducer(state.history, action),
        lastHistoryAction: new Date()
      };
    case ACTIONS.WEBSITE_ADD:
      const websiteId = v4();
      const tmpHistory = historyReducer(state.history, action);

      if (action.payload.campaignCleanUp) {
        forEach(values(tmpHistory[0].pages), (p) => {
          //Delete blocks related to campaign
          const campaignBlocksToDelete = map(
            filter(p.blocks, (b) => b.campaignId !== null && !b.hideForCampaign),
            (b2) => b2.id
          );
          if (campaignBlocksToDelete && campaignBlocksToDelete.length > 0) p.blocks = filter(p.blocks, (b) => !includes(campaignBlocksToDelete, b.id));

          // Delete campaign and hide from existing blocks
          const campaignBlocksToCleanUp = filter(p.blocks, (b) => b.campaignId !== null && b.hideForCampaign);
          forEach(campaignBlocksToCleanUp, (block) => {
            block.campaignId = null;
            block.hideForCampaign = false;
          });
        });
      }

      // Copy only customblocks that are used on current sites
      // Change website id of blocks
      let foundCustomBlocks = [];
      forEach(values(tmpHistory[0].pages), (p) => {
        const blocks = map(
          filter(p.blocks, (b) => b.contentType === 'CustomGeneratedBlockInBuilder'),
          (b2) => b2.componentName
        );
        if (blocks && blocks.length > 0) foundCustomBlocks = concat(foundCustomBlocks, blocks);
      });

      tmpHistory[0].customBlocks = filter(tmpHistory[0].customBlocks, (b) => {
        return includes(foundCustomBlocks, b.name);
      });

      forEach(tmpHistory[0].customBlocks, (b) => {
        b.websiteId = websiteId;
      });

      return {
        ...state,
        history: tmpHistory,
        websites: [...state.websites, { id: websiteId, name: action.payload.name }],
        currentWebsite: websiteId,
        lastSavedAsJson: JSON.stringify(tmpHistory[tmpHistory.length - 1]),
        loading: false,
        lastAction: undefined
      };
    case ACTIONS.WEBSITE_SELECT:
      return {
        ...initialState,
        currentWebsite: action.payload.websiteId
      };
    case ACTIONS.WEBSITE_DISABLE:
      return {
        ...state,
        websites: filter(state.websites, (w) => w.id !== action.payload.id)
      };
    case ACTIONS.DATA_SET:
      const history = historyReducer(state.history, action);
      return {
        ...state,
        history: history,
        lastSavedAsJson: JSON.stringify(history[history.length - 1]),
        loading: false,
        currentWebsite: action.payload.currentWebsiteId,
        configuration: action.payload.configuration,
        websites: action.payload.websites,
        contentTypeDefinitions: action.payload.contentTypeDefinitions,
        dealerships: action.payload.dealerships,
        dealershipLocationTypes: action.payload.dealershipLocationTypes,
        dealerMakes: action.payload.dealerMakes,
        bodyTypes: action.payload.bodyTypes,
        fuels: action.payload.fuels,
        gearboxes: action.payload.gearboxes,
        categories: action.payload.categories,
        labels: action.payload.labels,
        equipments: action.payload.equipments,
        exteriorColors: action.payload.exteriorColors,
        interiorColors: action.payload.interiorColors,
        interiorUpholsteries: action.payload.interiorUpholsteries,
        emissionNorms: action.payload.emissionNorms,
        pageTags: map(action.payload.pageTags, (t) => ({
          key: t.key,
          description: t.description
        }))
      };

    case ACTIONS.SESSION_SET:
      return {
        ...state,
        session: action.payload.session,
        sessionRestrictionModalView: action.payload.session.sessionId && !action.payload.session.editAllowed ? SessionRestrictionModalViews.RestrictedByToManyUsers : undefined,
        sessionTakeOverMessage: undefined
      };
    case ACTIONS.LAST_ACTION_SET:
      return {
        ...state,
        lastAction: Date.now(),
        lastSavedAsJson: JSON.stringify(state.history[state.history.length - 1])
      };
    case ACTIONS.MODAL_OPEN:
      return {
        ...state,
        modal: {
          type: action.payload.type,
          payload: action.payload.payload
        }
      };
    case ACTIONS.MODAL_CLOSE:
      if (action.payload.type === state.modal.type) {
        return {
          ...state,
          modal: undefined
        };
      }
      return state;
    case ACTIONS.CAMPAIGN_FILTER_CHANGE:
      return {
        ...state,
        campaignFilter: action.payload.filter
      };

    case ACTIONS.PUBLISH_START:
    case ACTIONS.PUBLISH_LOG_ADD:
    case ACTIONS.PUBLISH_LOG_CLEAR:
      return {
        ...state,
        publish: publishReducer(state.publish, action)
      };
    case ACTIONS.RESTRICTION_MODEL_VIEW_SET:
      return {
        ...state,
        sessionRestrictionModalView: action.payload.view
      };
    case ACTIONS.RESTRICTION_TAKE_OVER_MESSAGE_SET:
      return {
        ...state,
        sessionRestrictionModalView: action.payload.view,
        sessionTakeOverMessage: action.payload.message
      };
    case ACTIONS.RESET_STATE:
      return {
        ...initialState
      };
    case ACTIONS.PAGE_TAG_ADD:
      return {
        ...state,
        pageTags: [
          ...state.pageTags,
          {
            key: v4(),
            description: action.payload.tag
          }
        ]
      };

    default:
      return state;
  }
};

const historyReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.PAGE_ADD:
      return [
        ...state,
        {
          ...state[state.length - 1],
          pages: pagesReducer(state[state.length - 1].pages, action),
          currentPageId: action.payload.id
        }
      ];
    case ACTIONS.PAGE_DELETE:
      return [
        ...state,
        {
          ...state[state.length - 1],
          pages: pagesReducer(state[state.length - 1].pages, action),
          currentPageId: find(state[state.length - 1].pages, (page) => page.url === '/index/').id
        }
      ];
    case ACTIONS.PAGE_BLOCK_ADD:
    case ACTIONS.PAGE_BLOCK_DELETE:
    case ACTIONS.PAGE_BLOCK_ORDER:
    case ACTIONS.PAGE_BLOCK_CONTENT_CHANGE:
    case ACTIONS.PAGE_BLOCK_FIELD_CHANGE:
    case ACTIONS.PAGE_BLOCK_COMPONENT_CHANGE:
    case ACTIONS.PAGE_BLOCK_CONTENT_COPY:
    case ACTIONS.PAGE_BLOCK_CONTENT_COPY_TO_ALL:
    case ACTIONS.PAGE_BLOCK_CAMPAIGN_CHANGE:
    case ACTIONS.PAGE_BLOCK_HIDE_FOR_CAMPAIGN_CHANGE:
    case ACTIONS.PAGE_META_CHANGE:
    case ACTIONS.PAGE_PROPERTIES_CHANGE:
    case ACTIONS.PAGE_TAGS_CHANGE:
    case ACTIONS.PAGE_CHANGE:
      return [
        ...state,
        {
          ...state[state.length - 1],
          pages: pagesReducer(state[state.length - 1].pages, action)
        }
      ];
    case ACTIONS.CUSTOM_BLOCK_ADD:
    case ACTIONS.CUSTOM_BLOCK_DELETE:
      return [
        ...state,
        {
          ...state[state.length - 1],
          customBlocks: customBocksReducer(state[state.length - 1].customBlocks, action)
        }
      ];
    case ACTIONS.PAGE_SELECT:
      return [
        ...state,
        {
          ...state[state.length - 1],
          currentPageId: action.payload.id
        }
      ];
    case ACTIONS.BLOCK_SELECT:
      return [
        ...state,
        {
          ...state[state.length - 1],
          currentBlockId: action.payload.id
        }
      ];
    case ACTIONS.UNDO:
      if (state.length > 1) {
        return state.slice(0, state.length - 1);
      } else {
        return state;
      }
    case ACTIONS.LANGUAGES_CHANGE:
      const languagesCopy = [...state[state.length - 1].languages];
      const language = find(languagesCopy, (l) => l.code === action.payload.languageCode);
      if (language) {
        language.available = action.payload.available;
      }

      return [
        ...state,
        {
          ...state[state.length - 1],
          languages: languagesCopy
        }
      ];
    case ACTIONS.CONTENT_COPY:
      return [
        ...state,
        {
          ...state[state.length - 1],
          pages: keyBy(
            map(state[state.length - 1].pages, (page) => ({
              ...page,
              blocks: map(page.blocks, (block) => {
                const contentToAdd = map(block.content, (content) => ({
                  ...content,
                  id: v4(),
                  culture: action.payload.to,
                  fields: map(content.fields, (field) => ({
                    ...field
                  }))
                }));

                return {
                  ...block,
                  content: [...block.content, ...contentToAdd]
                };
              })
            })),
            'id'
          )
        }
      ];
    case ACTIONS.DATA_SET:
      const { pages, languages } = action.payload;
      const mappedPages = mapPagesToState(pages);
      const homePage = find(pages, (p) => p.type === 'index');

      return [
        {
          ...initialHistory,
          pages: keyBy(mappedPages.length > 0 ? mappedPages : [{ ...initialPage }], 'id'),
          pageContentSEO: map(action.payload.pageContent, (c) => {
            return {
              ...c,
              filters: JSON.parse(c.filters),
              text: JSON.parse(c.text)
            };
          }),
          campaigns: action.payload.campaigns,
          customBlocks: map(action.payload.customBlocks, (b) => {
            return {
              name: b.uuid,
              culture: b.culture,
              description: b.description,
              data: tryParse(b.data),
              contentType: 'CustomGeneratedBlockInBuilder'
            };
          }),

          advertisementGroups: map(action.payload.advertisementGroups, (advGroup) => {
            return {
              id: advGroup.id,
              name: advGroup.name,
              isDraft: advGroup.isDraft,
              disabled: advGroup.disabled
            };
          }),
          advertisements: map(action.payload.advertisements, (adv) => {
            return {
              id: adv.id,
              description: adv.description,
              content: adv.content,
              groups: adv.groups,
              isDraft: adv.isDraft,
              disabled: adv.disabled,
              filters: tryParse(adv.filters)
            };
          }),
          vehicleSearchFilters: map(action.payload.vehicleSearchFilters, (filter) => {
            return {
              id: filter.id,
              name: tryParse(filter.name),
              filters: tryParse(filter.filters),
              type: filter.type,
              isDraft: filter.isDraft,
              disabled: filter.disabled
            };
          }),

          languages: languages,
          currentPageId: homePage ? homePage.id : pages.length > 0 ? pages[0].id : initialPage.id,
          currentBlockId: undefined
        }
      ];
    case ACTIONS.SEO_PAGE_CONTENT_ADD:
    case ACTIONS.SEO_PAGE_CONTENT_UPDATE:
    case ACTIONS.SEO_PAGE_CONTENT_DELETE:
      return [
        ...state,
        {
          ...state[state.length - 1],
          pageContentSEO: seoPageContentReducer(state[state.length - 1].pageContentSEO, action)
        }
      ];
    case ACTIONS.CAMPAIGN_ADD:
      return [
        ...state,
        {
          ...state[state.length - 1],
          campaigns: [
            ...state[state.length - 1].campaigns,
            {
              id: v4(),
              name: action.payload.name,
              start: action.payload.start,
              stop: action.payload.stop,
              isDraft: action.payload.isDraft,
              disabled: false
            }
          ]
        }
      ];
    case ACTIONS.CAMPAIGN_DELETE:
      const campaign = find(state[state.length - 1].campaigns, (c) => c.id === action.payload.id);
      return [
        ...state,
        {
          ...state[state.length - 1],
          campaigns: [...state[state.length - 1].campaigns.filter((c) => c.id !== action.payload.id), { ...campaign, disabled: true }]
        }
      ];
    case ACTIONS.CAMPAIGN_EDIT:
      const oldCampaign = find(state[state.length - 1].campaigns, (c) => c.id === action.payload.id);
      const newCampaign = {
        ...oldCampaign,
        name: action.payload.name,
        start: action.payload.start,
        stop: action.payload.stop
      };
      return [
        ...state,
        {
          ...state[state.length - 1],
          campaigns: [...state[state.length - 1].campaigns.filter((c) => c.id !== oldCampaign.id), newCampaign]
        }
      ];
    case ACTIONS.CAMPAIGN_SELECT:
      return [
        ...state,
        {
          ...state[state.length - 1],
          campaignSelected: action.payload.id ? action.payload.id : undefined
        }
      ];
    case ACTIONS.CUSTOM_BLOCK_SELECT:
      return [
        ...state,
        {
          ...state[state.length - 1],
          customBlockSelected: action.payload.id ? action.payload.id : undefined
        }
      ];
    case ACTIONS.ADVERTISEMENT_INIT:
      return [
        ...state,
        {
          ...state[state.length - 1],
          advertisements: [
            ...state[state.length - 1].advertisements,
            {
              id: action.payload.id,
              description: action.payload.description ? action.payload.description : v4(),
              content: action.payload.content,
              groups: [],
              isDraft: action.payload.isDraft,
              filters: action.payload.filters,
              disabled: false
            }
          ]
        }
      ];

    case ACTIONS.ADVERTISEMENT_EDIT:
      const oldAdvertisement = find(state[state.length - 1].advertisements, (c) => c.id === action.payload.id);
      const newAdvertisement = {
        ...oldAdvertisement,
        description: action.payload.description,
        content: action.payload.content,
        groups: action.payload.groups,
        filters: action.payload.filters
      };
      return [
        ...state,
        {
          ...state[state.length - 1],
          advertisements: orderBy([...state[state.length - 1].advertisements.filter((c) => c.id !== oldAdvertisement?.id), newAdvertisement], 'id')
        }
      ];
    case ACTIONS.ADVERTISEMENT_DELETE:
      const advertisements = cloneDeep(state[state.length - 1].advertisements);
      forEach(advertisements, (adv) => {
        if (adv.id === action.payload.id) {
          adv.disabled = true;
        }
      });

      return [
        ...state,
        {
          ...state[state.length - 1],
          advertisements: advertisements
        }
      ];
    case ACTIONS.ADVERTISEMENT_GROUP_INIT:
      return [
        ...state,
        {
          ...state[state.length - 1],
          advertisementGroups: [
            ...state[state.length - 1].advertisementGroups,
            {
              id: action.payload.id,
              name: action.payload.name,
              isDraft: action.payload.isDraft,
              disabled: false
            }
          ]
        }
      ];

    case ACTIONS.ADVERTISEMENT_GROUP_EDIT:
      const oldAdvertisementGroup = find(state[state.length - 1].advertisementGroups, (c) => c.id === action.payload.id);
      const newAdvertisementGroup = {
        ...oldAdvertisementGroup,
        name: action.payload.name,
        disabled: action.payload.disabled | false
      };
      return [
        ...state,
        {
          ...state[state.length - 1],
          advertisementGroups: [...state[state.length - 1].advertisementGroups.filter((c) => c.id !== oldAdvertisementGroup?.id), newAdvertisementGroup]
        }
      ];
    case ACTIONS.ADVERTISEMENT_GROUP_DELETE:
      const advertisementGroups = cloneDeep(state[state.length - 1].advertisementGroups);

      forEach(advertisementGroups, (group) => {
        if (group.id === action.payload.id) {
          group.disabled = true;
        }
      });

      return [
        ...state,
        {
          ...state[state.length - 1],
          advertisementGroups: advertisementGroups
        }
      ];
    case ACTIONS.VEHICLE_FILTER_INIT:
      const existingFilter = find(state[state.length - 1].vehicleSearchFilters, (c) => c.id === action.payload.id);
      if (!existingFilter) {
        return [
          ...state,
          {
            ...state[state.length - 1],
            vehicleSearchFilters: [
              ...state[state.length - 1].vehicleSearchFilters,
              {
                id: action.payload.id,
                name: action.payload.name,
                type: action.payload.type,
                filters: [],
                isDraft: action.payload.isDraft,
                disabled: false
              }
            ]
          }
        ];
      } else {
        return state;
      }
    case ACTIONS.VEHICLE_FILTER_EDIT:
      const filters = cloneDeep(state[state.length - 1].vehicleSearchFilters);
      const f = find(filters, (f) => f.id === action.payload.id);

      f.name = action.payload.name;
      f.type = action.payload.type;
      f.filters = action.payload.filters;

      return [
        ...state,
        {
          ...state[state.length - 1],
          vehicleSearchFilters: filters
        }
      ];
    case ACTIONS.VEHICLE_FILTER_DELETE:
      const filtersDeleteCopy = cloneDeep(state[state.length - 1].vehicleSearchFilters);
      forEach(filtersDeleteCopy, (f) => {
        if (f.id === action.payload.id) {
          f.disabled = true;
        }
      });
      return [
        ...state,
        {
          ...state[state.length - 1],
          vehicleSearchFilters: filtersDeleteCopy
        }
      ];

    case ACTIONS.CUSTOM_BLOCK_EDIT:
      const oldCustomBlock = find(state[state.length - 1].customBlocks, (c) => c.name === action.payload.name);
      const newCustomBlock = {
        ...oldCustomBlock,
        description: action.payload.description,
        data: action.payload.data
      };
      return [
        ...state,
        {
          ...state[state.length - 1],
          customBlocks: [...state[state.length - 1].customBlocks.filter((c) => c.name !== oldCustomBlock.name), newCustomBlock]
        }
      ];
    case ACTIONS.WEBSITE_ADD:
      const current = state[state.length - 1];
      const copyPages = mapPages(current.pages, true);
      const copyPagesMapped = mapPagesToState(copyPages);

      return [
        {
          ...initialHistory,
          ...current,
          pages: keyBy(copyPagesMapped.length > 0 ? copyPagesMapped : [{ ...initialPage }], 'id'),
          currentPageId: copyPagesMapped.length > 0 ? copyPagesMapped[0].id : initialPage.id,
          currentBlockId: undefined
        }
      ];
    default:
      return state;
  }
};

function pagesReducer(state, action) {
  let copy = undefined;
  switch (action.type) {
    case ACTIONS.PAGE_ADD:
      let blocksCopy = [];
      if (action.payload.copyId) {
        blocksCopy = map(state[action.payload.copyId].blocks, (block) => ({
          ...block,
          id: v4(),
          content: map(block.content, (content) => ({
            ...content,
            id: v4()
          }))
        }));
      }

      return {
        ...state,
        [action.payload.id]: {
          ...initialPage,
          id: action.payload.id,
          url: action.payload.path,
          type: action.payload.type,
          blocks: blocksCopy
        }
      };
    case ACTIONS.PAGE_DELETE:
      return omit(state, [action.payload.id]);
    case ACTIONS.PAGE_BLOCK_ADD:
    case ACTIONS.PAGE_BLOCK_DELETE:
    case ACTIONS.PAGE_BLOCK_ORDER:
    case ACTIONS.PAGE_BLOCK_CONTENT_CHANGE:
    case ACTIONS.PAGE_BLOCK_FIELD_CHANGE:
    case ACTIONS.PAGE_BLOCK_COMPONENT_CHANGE:
    case ACTIONS.PAGE_BLOCK_CONTENT_COPY:
    case ACTIONS.PAGE_BLOCK_CAMPAIGN_CHANGE:
    case ACTIONS.PAGE_BLOCK_HIDE_FOR_CAMPAIGN_CHANGE:
      return {
        ...state,
        [action.payload.pageId]: {
          ...state[action.payload.pageId],
          blocks: pageBlocksReducer(state[action.payload.pageId].blocks, action)
        }
      };
    case ACTIONS.PAGE_BLOCK_CONTENT_COPY_TO_ALL:
      copy = cloneDeep(state);
      const isLmaPageBlock = action.payload.lmaPage;

      forEach(values(copy), (page) => {
        if ((isLmaPageBlock && includes(page.url, 'dealerGroup/')) || (!isLmaPageBlock && !includes(page.url, 'dealerGroup/'))) {
          forEach(page.blocks, (block) => {
            if (block.componentName === action.payload.block.componentName && block.contentType === action.payload.block.contentType && block.id !== action.payload.block.id) {
              const tmpContent = [];
              forEach(action.payload.block.content, (content) => {
                tmpContent.push({
                  id: v4(),
                  culture: content.culture,
                  fields: content.fields
                });
              });
              block.content = tmpContent;
            }
          });
        }
      });

      return copy;
    case ACTIONS.PAGE_META_CHANGE:
      return {
        ...state,
        [action.payload.pageId]: {
          ...state[action.payload.pageId],
          meta: metaReducer(state[action.payload.pageId].meta, action)
        }
      };
    case ACTIONS.PAGE_PROPERTIES_CHANGE:
      return {
        ...state,
        [action.payload.pageId]: {
          ...state[action.payload.pageId],
          properties: propertiesReducer(state[action.payload.pageId].properties, action)
        }
      };
    case ACTIONS.PAGE_CHANGE:
      return {
        ...state,
        [action.payload.page.id]: {
          ...state[action.payload.page.id],
          ...action.payload.page
        }
      };
    case ACTIONS.PAGE_TAGS_CHANGE:
      return {
        ...state,
        [action.payload.pageId]: {
          ...state[action.payload.pageId],
          tags: action.payload.tags
        }
      };
    default:
      return state;
  }
}

function pageBlocksReducer(state, action) {
  let copy = [];
  let blockToEdit = null;
  switch (action.type) {
    case ACTIONS.PAGE_BLOCK_ADD:
      copy = [...state];
      copy.splice(action.payload.index, 0, {
        id: v4(),
        componentName: action.payload.component.name,
        contentType: action.payload.component.contentType,
        content: []
      });
      return copy;
    case ACTIONS.PAGE_BLOCK_DELETE:
      return filter(state, (block) => block.id !== action.payload.blockId);
    case ACTIONS.PAGE_BLOCK_ORDER:
      copy = [...state];
      const { block, removedIndex, addedIndex } = action.payload;

      let itemToAdd = block;
      if (removedIndex !== null) {
        itemToAdd = copy.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        copy.splice(addedIndex, 0, itemToAdd);
      }
      return copy;

    case ACTIONS.PAGE_BLOCK_CONTENT_CHANGE:
      copy = cloneDeep(state);
      blockToEdit = find(copy, (block) => block.id === action.payload.blockId);
      const content = find(blockToEdit.content, (content) => content.culture === action.payload.culture);

      if (!content) {
        blockToEdit.content = [
          ...blockToEdit.content,
          {
            id: v4(),
            culture: action.payload.culture,
            fields: [
              {
                name: action.payload.field.name,
                type: action.payload.field.type,
                value: action.payload.value
              }
            ]
          }
        ];
      } else {
        const field = find(content.fields, (field) => field.name === action.payload.field.name);
        if (!field) {
          content.fields = [
            ...content.fields,
            {
              name: action.payload.field.name,
              type: action.payload.field.type,
              value: action.payload.value
            }
          ];
        } else {
          field.value = action.payload.value;
        }
      }
      return copy;

    case ACTIONS.PAGE_BLOCK_FIELD_CHANGE:
      copy = cloneDeep(state);
      blockToEdit = find(copy, (block) => block.id === action.payload.blockId);
      blockToEdit[action.payload.field] = action.payload.value;
      return copy;
    case ACTIONS.PAGE_BLOCK_COMPONENT_CHANGE:
      copy = cloneDeep(state);
      blockToEdit = find(copy, (block) => block.id === action.payload.blockId);
      blockToEdit.componentName = action.payload.componentName;
      return copy;

    case ACTIONS.PAGE_BLOCK_CONTENT_COPY:
      copy = cloneDeep(state);
      blockToEdit = find(copy, (block) => block.id === action.payload.blockId);
      blockToEdit.content = [];
      forEach(action.payload.copyBlock.content, (copyContent) => {
        blockToEdit.content.push({
          id: v4(),
          culture: copyContent.culture,
          fields: copyContent.fields
        });
      });

      return copy;

    case ACTIONS.PAGE_BLOCK_CAMPAIGN_CHANGE:
      copy = cloneDeep(state);
      blockToEdit = find(copy, (block) => block.id === action.payload.blockId);
      blockToEdit.campaignId = action.payload.campaignId;
      return copy;
    case ACTIONS.PAGE_BLOCK_HIDE_FOR_CAMPAIGN_CHANGE:
      copy = cloneDeep(state);
      blockToEdit = find(copy, (block) => block.id === action.payload.blockId);
      blockToEdit.hideForCampaign = action.payload.hide;
      return copy;
    default:
      return state;
  }
}

function metaReducer(state, action) {
  let copy = [];
  switch (action.type) {
    case ACTIONS.PAGE_META_CHANGE:
      if (state) {
        copy = cloneDeep(state);
      } else {
        copy = [];
      }

      const metaToEdit = find(copy, (meta) => meta.id === action.payload.meta.id);

      if (metaToEdit) {
        metaToEdit.title = action.payload.meta.title;
        metaToEdit.description = action.payload.meta.description;
      } else {
        copy.push({
          id: action.payload.meta.id,
          language: action.payload.culture,
          title: action.payload.meta.title,
          description: action.payload.meta.description
        });
      }

      return copy;
    default:
      return state;
  }
}

function propertiesReducer(state, action) {
  let copy = [];
  switch (action.type) {
    case ACTIONS.PAGE_PROPERTIES_CHANGE:
      if (state) {
        copy = cloneDeep(state);
      } else {
        copy = [];
      }

      const property = find(copy, (p) => p.type === action.payload.type && p.language === action.payload.culture);

      if (property) {
        property.data = action.payload.data;
      } else {
        copy.push({
          id: v4(),
          language: action.payload.culture,
          type: action.payload.type,
          data: action.payload.data
        });
      }

      return copy;
    default:
      return state;
  }
}

function seoPageContentReducer(state, action) {
  let copy = [];
  switch (action.type) {
    case ACTIONS.SEO_PAGE_CONTENT_ADD:
      return [
        ...state,
        {
          key: action.payload.key,
          language: action.payload.language,
          type: action.payload.type,
          filters: action.payload.filters,
          text: action.payload.text
        }
      ];
    case ACTIONS.SEO_PAGE_CONTENT_UPDATE:
      copy = cloneDeep(state);
      const itemToEdit = find(copy, (item) => item.key === action.payload.key && item.language === action.payload.language);

      if (itemToEdit) {
        itemToEdit.text = action.payload.text;
      }

      return copy;
    case ACTIONS.SEO_PAGE_CONTENT_DELETE:
      return state;
    default:
      return state;
  }
}

function customBocksReducer(state, action) {
  switch (action.type) {
    case ACTIONS.CUSTOM_BLOCK_ADD:
      return [...state, { ...action.payload }];
    case ACTIONS.CUSTOM_BLOCK_DELETE:
      return omit(state, action.payload.uuid);
    default:
      return state;
  }
}

function publishReducer(state, action) {
  switch (action.type) {
    case ACTIONS.PUBLISH_START:
      return {
        ...state,
        output: ''
      };
    case ACTIONS.PUBLISH_LOG_ADD:
      return {
        ...state,
        // output: `${state.output} \n ${action.payload.data}`
        output: `${state.output}${action.payload.data}`
      };
    case ACTIONS.PUBLISH_LOG_CLEAR:
      return {
        ...state,
        output: ''
      };
    default:
      return state;
  }
}
