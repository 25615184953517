export const DataQuery = `
query ($websiteId: String) {
  websites {
    id
    name
    isProduction
  }
  website(id: $websiteId) {
    id
    name
    isProduction
    pages {
      id
      url
      type
      slug
      migration
      creationDate
      tags
      indexable
      matchPath
      analyticsName
      analyticsType
      meta {
       id
       title
       description
       language
      }
      properties {
       id     
       type
       data
       language
      }
      blocks {
        id
        order
        contentType
        variant
        content
        campaignId
        hideForCampaign
        analyticsName
      }
    }
   
    
  
   customBlocks {
    uuid
    description
    culture
    data
   }
  }
  
  campaigns {
     id
     name
     start
     stop
     isDraft
     disabled
   }
  
  advertisementGroups {
    id
    name   
    isDraft
    disabled
  }
  
  advertisements {
    id
    description
    content {
      id
      culture
      data
    }
    groups    
    isDraft
    disabled
    filters
  }
   
  pageContent {
    key
    type
    text
    filters
    language
  }
  
  configuration
  contentTypeDefinitions {
    name
    fields {
      name
      type
      label
      description
    }
  }
  dealerships {
    id
    name
    reference
  }
  dealershipLocationTypes {
    id
    name
  }
  dealerMakes {
    key
    name
    models {
      name
      key
    }
  }
  bodyTypes {
    key
    description
  }
  fuels {
    key
    description
  }
  gearboxes {
    key
    description
  }
  categories {
    key
    description
  }
  labels {
    key
    description
  }
  features {
    key
    description
  }
  exteriorColors {
    key
    description
    colorCode
  }
  interiorColors {
    key
    description
    colorCode
  }
  interiorUpholsteries {
    key
    description
  }
  euroNorms {
    key
    description
  }
  
  tags {
    key
    description
  } 
}
`;

export const WebsiteQuery = `{
    websiteProd {
        id
        name
        isProduction
     
        pages {
          id      
          url    
          type
          translations
          analyticsName
          analyticsType
          meta {
            id
            title
            description
            language
          }  
          blocks {
            id        
            order
            contentType
            variant
            content
            campaignId
            hideForCampaign
            analyticsName
          }
        }
    }    
}`;

export const DealershipTypeTypesQuery = `{
    dealershipLocationTypes {
        id
        name
        description
      }
}`;

export const DealershipQuery = `query ($id: String!) { 
        dealership(id: $id) { 
            id
            name
            address
            postal
            city
            reference
            email
            facebook
            twitter
            instagram
            linkedin
            fax
            tel 
            url
            image
            openingsHours {
                uuid
                day
                start
                stop
                type {
                  id
                  name
                  description
                }
            }
            closingDays {
                uuid
                start
                stop
                description
                type {
                  id
                  name
                  description
                }
            } 
        }
    }`;

export const DealershipsQuery = `{
  dealerships {
    id
    name
    address
    postal
    city
    reference
    facebook
    twitter
    instagram
    linkedin
    email
    fax
    tel 
    url
    image
    openingsHours {
        uuid
        day
        start
        stop
        type {
          id
          name
          description
        }
    }
    closingDays {
        uuid
        start
        stop
        description
        type {
           id
           name
           description
        }
    }
  }    
}`;

export const SettingsQuery = `{
    settings   
}`;

export const DealershipUpdateMutation = `mutation DealershipUpdate($data: DealershipInputType!) { 
        dealershipUpdate(data: $data) { 
            ok 
            dealership {
                id
                name
                address
                postal
                city
                reference
                facebook
                twitter
                instagram
                linkedin
                email
                fax
                tel 
                url
                image
                openingsHours {
                    uuid
                    day
                    start
                    stop
                    type {
                      id
                      name
                      description
                    }
                }
                closingDays {
                    uuid
                    start
                    stop
                    description
                    type {
                       id
                       name
                       description
                    }
                }
            }
        }
    }`;

export const SiteUpdateMutation = `mutation SiteUpdate($data: SiteInputType!) { 
        siteUpdate(data: $data) { 
            ok 
        }
    }`;

export const DraftToProductionMutation = `mutation DraftToProduction($website: UUID!) { 
        draftToProduction(website: $website) { 
            ok 
        }
    }`;

export const DeleteDraftMutation = `mutation DraftDelete($website: UUID!) { 
        draftDelete(website: $website) { 
            ok 
        }
    }`;

export const SettingsUpdateMutation = `mutation SettingsUpdateV2($data: SettingsInputType!) { 
        settingsUpdate(data: $data) { 
            ok 
        }
    }`;

export const FileUploadMutation = `mutation FileUpload($file: Upload!) { 
        fileUpload(file: $file) { 
            ok 
            uuid
            location
            url            
        }
    }`;

export const ExecuteMutation = `mutation Execute($action: String!, $project: String!, $payload: JSONString) { 
        execute(action:$action, project:$project, payload: $payload) { 
            ok          
        }
    }`;
